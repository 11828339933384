var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("div", { staticClass: "fz-20 fz-w" }, [
            _vm._v("用户注册增长趋势")
          ]),
          _c("el-date-picker", {
            attrs: {
              size: "small",
              valueFormat: "yyyy",
              format: "yyyy年",
              type: "year",
              clearable: false,
              placeholder: "选择日期时间"
            },
            on: { change: _vm.getData },
            model: {
              value: _vm.date,
              callback: function($$v) {
                _vm.date = $$v
              },
              expression: "date"
            }
          })
        ],
        1
      ),
      _c("echarts", { attrs: { height: "300px", options: _vm.options } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }